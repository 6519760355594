import { useQuery } from "@tanstack/vue-query";
import { AxiosResponse } from "axios";
import { storeToRefs } from "pinia";
import { useWalletStore } from "~/stores/walletStore";
import { IEntityWallet } from "~/types/schema";

export const useGetListEntityWallets = () => {
  const { listEntityWallets } = storeToRefs(useWalletStore());
  const { $api2 } = useNuxtApp();
  return useQuery<IEntityWallet[]>({
    queryKey: ["useGetListEntityWallets"],
    queryFn: async () => {
      const response = (await $api2(
        "/api/wallets/entity-wallets"
      )) as AxiosResponse<IEntityWallet[]>;
      const wallets = response.data;
      listEntityWallets.value = wallets as any;
      return wallets;
    },
  });
};

export const useGetListCurrenciesRate = () => {
  const { listCurrencies } = storeToRefs(useWalletStore());
  const { $api2 } = useNuxtApp();
  const { data, ...rest } = useQuery({
    queryKey: ["useGetListCurrenciesRate"],
    queryFn: async () => {
      const response = (await $api2(
        "/api/wallets/entity-wallets/rate"
      )) as AxiosResponse<any>;
      const currencies = response.data;
      listCurrencies.value = currencies;
      return currencies;
    },
    staleTime: 30000,
  });
  return { data: listCurrencies, ...rest };
};
